export function serializeList(list) {
  if (!list) return [];
  return list.map((item) => ({
    ...item,
    name: `<li>${item.name}</li>`,
  }));
}

export function deserializeList(stringList) {
  if (!stringList) return [];
  return stringList.match(/<li>(.*?)<\/li>/g).map((item) => ({
    name: item.replace("<li>", "").replace("</li>", ""),
  }));
}
